/*
Add brackets around non-empty filter
 */
.dc-chart .filter:not(:empty):before {
    content: '[';
}

.dc-chart .filter:not(:empty):after {
    content: ']';
}

.histogram-chart .filter:not(:empty):before {
    content: '';
}

.histogram-chart .filter:not(:empty):after {
    content: '';
}

.base-tooltip {
    position: absolute;
    display: none;
    min-width: 80px;
    background-color: var(--color-white);
    border: solid 0.5px var(--color-gray-chateau);
    border-radius: 2px;
    color: var(--color-gray-big-stone);
    font-size: 10px;
    padding: 3px;
    text-align: center;
}

.base-tooltip:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 5px var(--color-gray-chateau);
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
}

/* this goes on top of the :before triangle to give the
   illusion of a border -> a white triangle on top of a black one
*/
.base-tooltip:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 4px var(--color-white);
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
}

.histogram-chart .x .tick line{
    display: none;
}

.histogram-chart.circle-brush .brush .custom-brush-handle {
    fill: #ffffff;
    stroke: var(--color-gray-chateau);
}

.dc-text-filter-input {
    border-radius: 3px;
    height: 33px;
    width: 100%;
}

/* Reserve the space for the filters */
.dc-chart .filter:empty {
    height: 100%;
}

.dc-chart.sparkline .axis { 
    display: none;
}

.dc-chart .no-data {
    display: none;
}

.dc-chart-container.state-empty .no-data {
    display: block;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 90px);
    color: var(--color-gray-chateau);
    font-size: 17px;
}


.state-loading .dc-chart.content .y-axis-label,
.state-loading .dc-chart.content .x-axis-label,
.state-loading .starch-tree-map.content .node,
.state-loading .content .dc-text-filter-input {
    visibility: hidden;
}

.dc-histogram__manual {
    display: grid;
    grid-template-columns: 100px 1fr 100px;  
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.dc-histogram__x-axis-label {
    text-align: center;
}

.dc-histogram__manual .text-input__input {
    height: 23px;
}

.dc-chart .selection {
    fill: var(--neutrals-n6);  
    opacity: 0.7;  
}