.data-out-chart .content {
    min-height: 100px;
}

.historic-lookback .data-out-chart .content {
    min-height: 200px;
}

.data-out-chart .dc-chart rect.bar {
    cursor: default;
}

.data-out-chart .dc-legend .dc-legend-item {
    /* You can't do anything with the legend so don't show the hand cursor */
    cursor: default;
}

.data-out-filter {
    padding: 0 15px;
}

