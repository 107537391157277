@import 'Terrene.css';
.text-input {
    width: 100%;    
}

.text-input__label {
    font-weight: var(--terrene-label-weight);
}

.text-input__input {
    box-sizing: border-box;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px var(--terrene-bottom-border-color);
    height: var(--terrene-field-height);
    outline: none;
    padding: 9px;
    width: 100%;
}

.text-input__input:focus {
    border-bottom-color: var(--terrene-active-color);
}

.has-error > .text-input__input,
.has-error > .text-input__input:focus {
    border-color: var(--terrene-error-color);
}

.text-input__helper-text {
    color: gray;
    padding-top: 5px;
    padding-left: 5px;
}

.text-input__helper-text._error {
    color: var(--terrene-error-color);
}
