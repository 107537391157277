.data-in-chart .content {
    min-height: 220px;
}


.data-in-chart .dc-chart rect.bar {
    cursor: default;
}

.data-in-chart .x.axis  g:first-of-type text,
.data-in-chart .x.axis  g:last-of-type text {
    /* Hide the tick label that comes from xAxisPadding */
    display: none;
}

.data-in-chart .grid-line {
    stroke: var(--color-gray-chateau);
    stroke-opacity: 0.8;
    stroke-dasharray: 2;
}

.data-in-filter {
    padding: 0 15px;
}