:root {
    --background: var(--neutrals-n0);
}

.client-activity-report {
    --car-primary-color: var(--chart-color-2);
    --car-accent-color: var(--chart-color-3);

    --line-chart-color: var(--car-primary-color);
    --bar-chart-color: var(--car-accent-color);

    --section-height: 282px;
    --section-gap: 32px;
}

.client-activity-report .card-header {
    /* h18 medium */
    font-size: 18px;
    line-height: 21px;
    font-weight: var(--font-weight-medium);
}

@media (max-width: 1200px) {
    .client-activity__transaction-row section {
        /* We only need space if they are wrapping */
        margin-bottom: var(--section-gap);
    }

    .client-activity__header {
        margin-bottom: 0;
    }
}

.client-activity__header {
    margin-bottom: var(--section-gap);
}

.client-activity-report .sparkline {
    position: relative;
    height: 50px;
}

.client-activity-report .sparkline path.line,
.client-activity-report .sparkline .dot {
    stroke: var(--line-chart-color);
    stroke-width: 2px;
    fill: none;
}

.client-activity-section {
    margin-top: var(--section-gap);
}

.client-activity-behavior-breakdown .starch-slopegraph .slope-line,
.client-activity-behavior-breakdown .starch-slopegraph  circle {
    stroke: var(--car-accent-color);
}

.client-activity-transactions .starch-slopegraph .slope-line,
.client-activity-transactions .starch-slopegraph  circle {
    stroke: var(--line-chart-color);
}

.client-activity-channels .starch-slopegraph .slope-line,
.client-activity-channels .starch-slopegraph  circle {
    stroke: var(--line-chart-color);
}


.client-activity-list .bar {
    fill: var(--bar-chart-color);
}


.client-activity-report .dc-chart-container {
    position: relative;    
}


 .client-activity-report .state-loading svg {
    display: none;
}

.client-activity-report .dc-chart-container.state-empty .tag-uniques-filter .no-data {
    display: none;
}


.client-activity-report .dc-chart-container.state-empty .grid-line {
    display: none;
}

.client-activity-report .reset {
    display: none;
}

/**
Behavior Card
**/
.client-activity-behaviors .card-body {
    align-items: center;
}

.behavior-card {
    padding: 0 15px;;
}

.behavior-card-summary {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr [Uncategorized] 25px [Divider] 1fr [Total];
}

.behavior-card-total-container {
    justify-self: end;
}

.page-scheme-umbra .behavior-card .progress {
    background-color: var(--color-gray-big-stone);
    height: 17px;
}

/* Overrides for this percentage bar */
.behavior-card .progress-percent {
    display: none;
}

.behavior-card .progress {
    border-radius: 0;
    height: 20px;
}

.behavior-card .content-label {
    max-width: 155px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.lookback-link {
    position: absolute;
    right: 0px;
    top: -10px;
}

.client-activity-report .grid-line {
    stroke: var(--color-gray-chateau);
    stroke-opacity: 0.8;
    stroke-dasharray: 2;
}

.client-activity-report .line {
    fill: none;
    stroke-width: 2px;
    stroke: var(--line-chart-color);
}

 .client-activity-report .dc-legend-item rect {
    /* Turn the rect into a circle */
    rx: 12px;
}

 .client-activity-report .dc-legend-item text {
    alignment-baseline: middle;
}

.client-activity-report .initial-fill .area {
    fill: transparent;
}

.client-activity-report .dot {
    fill: var(--color-white);
    stroke-opacity: 1 !important;
    stroke: var(--line-chart-color);
}


.client-activity-report .sparkline .dot {
    stroke-opacity: 0 !important;
}

.client-activity-report  .sparkline .dot:hover {
    fill: var(--color-white);
    stroke-opacity: 1 !important;
    stroke-width: 1px;
    stroke: var(--color-primary);
}

.client-activity-report .axis path {
    stroke: var(--color-gray-chateau);
}

.client-activity-report .tick line {
    stroke: none !important;
}

.client-activity-report .x.axis g[transform="translate(0.5,0)"] text {
    /* Hide the tick label that comes from xAxisPadding */
    display: none;
}
.client-activity-report text.x-axis-label {
    font-weight: 500;
}
.lookback-totals.client-activity-transactions .metric-card-container {
    display: block;
}

/* tweaking min heights to get cards to line up */
.client-activity-section .card {
    height: var(--section-height);
}

.client-activity-report .client-activity-campaigns>.card,
.client-activity-report .client-activity-models>.card {
    height: calc(var(--section-height) / 2 - var(--section-gap ) / 2);
}


.client-activity-report .client-activity-total-uniques > .card,
.client-activity-report .client-activity-client-uniques > .card,
.client-activity-report .client-activity-transactions > .card {
    min-height: 125px;
}

.card-overflow__item {
    padding: 8px;
}

.metric-card-type-billing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px 4px;
    height: 222px;
}

.metric-card__metric {
    min-height: 0;
    flex: 1 1 50px;
}
