.email-client-stats-chart .content {
    min-height: 200px;
}


.email-client-stats-chart .dc-chart rect.bar {
    cursor: default;
}

.email-client-stats-chart .x.axis  g:first-of-type text,
.email-client-stats-chart .x.axis  g:last-of-type text {
    /* Hide the tick label that comes from xAxisPadding */
    display: none;
}

.email-client-stats-chart .grid-line {
    stroke: var(--color-gray-chateau);
    stroke-opacity: 0.8;
    stroke-dasharray: 2;
}



.email-client-stats-filter {
    padding: 0 15px;
}