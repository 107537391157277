.percentage-bar[data-text-placement="outside"] {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 20px;

    --pb-bar-height: 6px;
    --pb-font-size: 12px;
}

.percentage-bar[data-text-placement="inside"] {
    --pb-bar-height: 14px;
    --pb-font-size: 11px;
    vertical-align: middle;
}

.progress-percent {
    font-size: var(--pb-font-size);
    font-weight: var(--font-weight-light);
}

.progress {
    background-color: var(--neutrals-n4);
    border-radius: 6px;
    height: var(--pb-bar-height);
    color: var(--neutrals-n10);
}

.progress-bar {
    box-shadow: none;    
}