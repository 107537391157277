[data-metric-card-size="sm"] {
    --sq-height: 60px;
    --sq-width: 60px;
}

[data-metric-card-size="md"] {
    --sq-width: 60px;
    --sq-height: 240px;
}

[data-metric-card-size="lg"] {
    --sq-width: 60px;
    --sq-height: 360px;
}

[data-metric-format="compact"] {
    --metric-gap: 0;
}


.metric-card {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: 100px;
    width: 100%;
    padding: 15px;

    --metric-gap: 15px;
}


.metric-card-name {    
    padding-bottom: 15px;
}

.metric-card-value.no-data {
    color: var(--color-gray-chateau);
    font-size: 13px;
    font-weight: normal;
}

.metric-card .metric-threshold-container {
    margin-bottom: 10px;
}

.metric-card-1 {
    grid-template-columns: 1fr;
}

.metric-card-container {
    align-items: flex-end;
    display: grid;

    /* min width needs to be 0, instead of the default of auto so that it shrinks */
    grid-template-columns: fit-content(150px) minmax(0, 1fr);
    grid-gap: var(--metric-gap);
    justify-items: center;
}


.metric-card-container > .content {
    line-height: initial;
}

.metric-card-cta {
    align-self: end;
    justify-self: end;
}

.audit-log-link a[href=''] {
    color: #000000;
    cursor: default;
}

.metric-card-icon-container {
    align-items: center;
    color: var(--color-white);
    display: flex;
    font-size: 24px;
    height: var(--sq-height);
    justify-content: center;
}

.metric-card-icon-container .lotacon {
    cursor: default;
}

.metric-card-value {
    text-align: left;
    white-space: nowrap;
}

/* Prevent overflow on smaller screens */
@media (max-width: 1366px) {
    .dc-chart-container.state-empty .no-data {
        font-size: 14px;
    }
}

.warning-level-one {
    color: var(--orange-o3);
}

.warning-level-two {
    color: var(--red-r3);
}

.client-activity-report .metric-card-graph.warning-level-one .sparkline .chart-body path.line {
    stroke: var(--orange-o3);
}

.client-activity-report .metric-card-graph.warning-level-two .sparkline .chart-body path.line {
    stroke: var(--red-r3);
}

.friendly-standard {
    font-weight: 300;
    text-transform: capitalize;
}

.behavior-card-total {
    color: var(--color-gray-big-stone);
}

.metric-card__threshold-warning {
    visibility: hidden;
    /* font-size: 20px; */
}

.warning-level-one .metric-card__threshold-warning,
.warning-level-two .metric-card__threshold-warning {
    visibility: visible;
    left: 2px;
    vertical-align: -2px;
}

.metric-card-header {
    display: grid;
    grid-template-columns: 1fr 36px;
    width: 100%;
    align-items: center;
}

.metric-card-header .card-overflow i {
    color: var(---neutrals-n7);
}