.audience-card {
    height: 151px;
    padding: 8px 15px;
}

.audience-card__active-count {
    justify-self: end;
}

.client-activity-audiences .card-footer,
.client-activity-behaviors .card-footer {
    padding-top: 0;
    padding-bottom: 15px;
    justify-content: flex-end;
}

.audience-card-links li {
    margin-top: 10px;
}

.audience-link__value {
    padding-left: 6px;
}
