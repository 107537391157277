.card {
    --card-background-color: var(--color-white);
    --card-contrast-background-color: var(--neutrals-n4);
    --card-header-font-weight: normal;
    --card-header-font-size: 18px;
    --card-header-padding: 13px 15px;
    --card-border-radius: 4px;

    --card-padding: 10px;
    --grid-gap: 0;

    background: var(--card-background-color);
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); 
    border-radius: var(--card-border-radius);
    display: flex;
    flex-direction: column;
}

.card-soft {
    box-shadow: none;
    --card-background-color: var(--color-white-alabaster);
}

.card-terrene {
    --card-contrast-background-color: var(--card-background-color);
    --card-header-font-weight: 500;
    --card-header-padding: 15px;
}


.card-header {
    align-items: center;
    background: var(--card-contrast-background-color);
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    color: var(--neutrals-n12);
    display: flex;
    flex-direction: row;
    font-size: var(--card-header-font-size);
    font-weight: var(--card-header-font-weight);
    padding: var(--card-header-padding);
}

.card-terrene .card-header {
    padding-left: 14px;
}

.card-header.with-action {
    justify-content: space-between;
}

.card-body {
    flex: 1;
    justify-content: center;
    display: flex;
    gap: var(--grid-gap)
}
.card-light .card-footer {
    background: var(--card-background-color);
}

.card-footer {
    align-items: flex-end;
    background: var(--card-contrast-background-color);
    display: flex;
    justify-content: space-between;

    min-height: 30px;
}

.card-align-right {
    margin-left: auto;
}

.card-body.stacked {
    flex-direction: column;
    padding: var(--card-padding);
}

.card-separator {
    padding: 4px 16px;
    background-color: var(--neutrals-n10);
}

/*
Add some margin at the bootstrap md column breakpoint
 */
@media (max-width: 992px) {
    .card {
        margin-top: 25px;
    }
}

/* Add space between cards 
* 1. Nested Cards
* 2. Multiple cards but not nested
* 3. Multiple cards nested in rows
*/
.card .stacked .card,
.stacked .card + .card,
.stacked .row .card {
    margin-top: var(--margin-double);
}
